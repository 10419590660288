import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/components/Layout'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    redirect: 'home',
    component: layout,
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/HomeView.vue'),
        meta: { title: '首页' },
      },
      {
        path: '/group',
        name: 'group',
        component: () => import('@/views/group/index'),
        meta: { title: '服务团队' },
      },
      {
        path: '/client',
        name: 'client',
        component: () => import('@/views/client/index'),
        meta: { title: '下载客户端' },
      },
      {
        path: '/joinus',
        name: 'joinus',
        component: () => import('@/views/joinus/index'),
        meta: { title: '加入我们' },
      },
    ],
  },
  // 路由重定向
  {
    path: '*',
    name: '404',
    redirect: '/home',
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
