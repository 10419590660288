<template>
  <div id="layout" class="scroll">
    <div class="container container-md container-lg">
      <div class="logo">
        <!-- <div class="img"></div> -->
        <img src="../../../assets/logo.png" alt="">
        <div class="logoTitle">找个人</div>
      </div>
      <nav class="top-nav">
        <ul class="top-nav">
          <li class="page-scroll" v-for="item in titleList" :key="item.path" @click="() => (activeIndex = item.path)">
            <router-link :to="item.path" :class="{ active: activeIndex == item.path }" class="scroll">{{ item.name
              }}</router-link>
          </li>
        </ul>
        <a @click="show = !show" id="pull"><img src="@/static/images/nav-icon.png" title="menu" /></a>

      </nav>
      <ul class="navList" v-if="show">
        <li class="page-scroll" v-for="item in titleList" :key="item.path" @click="() => (activeIndex = item.path)">
          <router-link :to="item.path" :class="{ active: activeIndex == item.path }" class="scroll">{{ item.name
            }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'layoutHeader',
  data() {
    return {
      show: false,
      activeIndex: 'home',
      titleList: [
        { name: '首页', path: 'home' },
        { name: '公司简介', path: 'group' },
        { name: '下载客户端', path: 'client' },
        { name: '加入我们', path: 'joinus' },
      ],
    }
  },
  watch: {
    '$route'() {
      this.activeIndex = this.$route.name
    }
  },
  created() {
    this.activeIndex = this.$route.name
  },
}
</script>

<style scoped>
@import '@/static/css/theme-style.css';

.share .slide-text {
  align-items: flex-start;
}
#layout{
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: url(@/assets/images/home/head_bg.png) no-repeat;
  background-size: 100% 96vh; */
  position: relative;
  z-index: 999999;
}
.container {
  .logo {
    display: flex;
    align-items: center;

    .logoTitle {
      width: 90px;
      height: 36px;
      font-family: eryaxindahei, eryaxindahei;
      font-weight: 400;
      font-size: 30px;
      color: #ffffff;
      line-height: 35px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-left: 10px;
    }

    img {
      width: 36px;
      height: 36px;
      /* background: linear-gradient(90deg, #7530ff 0%, #9b1cff 100%); */
      border-radius: 8px;
    }
  }

  .btns {
    margin-top: 40px;
    text-align: left;
    display: flex;
    align-items: center;

    .slide-btn {
      display: block;
      position: relative;
      width: 113px;
      height: 37px;
      font-family: PingFang SC, PingFang SC;
      background: url(@/assets/images/home/home_btn.png) no-repeat;
      background-size: contain;
      font-size: 12px;
      color: #ffffff;
      margin-right: 16px;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 11px;
        height: 12.5px;
      }
    }

    .slide-btn:hover {
      border: none;
      background: url(@/assets/images/home/home_btn1.png) no-repeat;
      width: 113px;
      height: 37px;
      background-size: contain;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin-right: 16px;
    }
  }
}

.navList {
  position: absolute;
  top: 80px;
  left: 40px;
  list-style: none;
  z-index: 99999999;
  margin: 0 40px;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  /* background: linear-gradient(to bottom, #510e84, #0f1123); */

  li {
    font-size: 24px;
    /* display: block; */
    padding: 40px 0;

    a {
      color: #fff;

    }
  }
}
</style>
