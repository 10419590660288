<template>
  <div class="home">
    <LayoutHeader></LayoutHeader>

    <router-view class="header" />

    <LayoutFooter></LayoutFooter>
  </div>
</template>

<script>
import LayoutHeader from './components/LayoutHeader.vue'
import LayoutFooter from './components/LayoutFooter.vue'
export default {
  name: 'MyLayout',
  components: {
    LayoutFooter,
    LayoutHeader,
  },
}
</script>
<style></style>
