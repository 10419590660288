<template>
  <div class="footer">
    <div class="container">
      <div class="item Logo flex">
        <img src="@/assets/logo.png" alt="" />
        <div class="name">
          <div class="title">找个人</div>
          <div class="desc">专业服务·收费透明·严禁推销</div>
        </div>
      </div>
      <div class="DivisionLine"></div>
      <div class="item QrCode">
        <img src="@/assets/images/footer/qrCode.jpg" alt="" />
        <div class="QrCode-describe">微信扫一扫进入小程序</div>
      </div>
      <div class="DivisionLine"></div>
      <div class="item RecordNumber">
        <div class="name">联系电话: 158-1553-7031</div>
        <div class="name">备案号: 粤ICP备200059557</div>
        <div class="name">企业邮箱: wzh@zhaoge.ren</div>
        <div class="name">客服热线: 400-900-3239</div>
        <div class="name">公司地址：深圳市罗湖区中设广场B座1007</div>
        <!-- <img src="@/assets/images/footer/Group2.png" alt="" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.footer {
  padding: 60px 0;
  background: url(https://zhaogeren.oss-cn-shenzhen.aliyuncs.com/images/web/home/bottom_bg.png) no-repeat;
  background-size: cover;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    .Logo {
      img {
        width: 70px;
        height: 70px;
      }
      .name{
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .title{
          font-family: "eryaxindahei";
          color: #ffffff;
          font-size: 50px;
        }
        .desc{
          font-size: 12px;
          color: #ffffff;

        }
      }
    }
    .QrCode {
      img {
        width: 116px;
        height: 116px;
        border-radius: 10px;
      }
    }
    .QrCode-describe {
      font-family: 'PingFang SC-Regular';
      font-size: 14px;
      color: #ffffff;
      margin-top: 10px;
    }
    .DivisionLine {
      width: 2px;
      height: 60px;
      background-color: #9b1cff;
    }
  }
}
.RecordNumber {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  color: #ffffff;
  .name {
    margin-top: 12px;
    font-size: 12px;
    line-height: 15px;
  }
}
</style>
