import { render, staticRenderFns } from "./LayoutFooter.vue?vue&type=template&id=1fd60aca&scoped=true"
import script from "./LayoutFooter.vue?vue&type=script&lang=js"
export * from "./LayoutFooter.vue?vue&type=script&lang=js"
import style0 from "./LayoutFooter.vue?vue&type=style&index=0&id=1fd60aca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fd60aca",
  null
  
)

export default component.exports